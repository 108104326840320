import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';

import MEDIA_QUERY from '../constants/media-queries';
import Colors from '../constants/colors';

const MenuLink = styled(Link)`
  font-family: "Courier", monospace;
  font-size: 1.0em;
  line-height: 2em;
  text-decoration: underline;

  color: #FFFFFF;
  background: ${Colors.PURPLE};

  margin-top: 1.25em;
  padding-left: 1.0em;
  padding-right: 1.0em;

  // For larger screens
  ${MEDIA_QUERY.M} {
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;
  }

  &:hover {
    background: #3D58ED;
    color: #000000;
    background: transparent;
  }

  &:active {
    background: ${Colors.PURPLE};
  }
`;

export default (props) => <MenuLink {...props} />;
