import React, { Component } from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import MEDIA_QUERY from '../constants/media-queries';

const Wrap = styled.div`
  position: fixed;
  z-index: 1000;
  background: yellow;
  border: 20px blue solid;
  padding: 20px;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  top: 0;
  font-size: 1.15em;
  width: 100vw;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  div.closeButton {
    width: 100%;
    text-align: center;

    a {
      cursor: pointer;
    }
  }

  ${MEDIA_QUERY.M} {
    overflow-y: auto;
  }

  a {
    font-weigth: bold;
  }

  span {
    font-weight: bold;
  }
`

class ReliefFund extends Component {
  constructor() {
    super();

    this.state = {
      isVisible: true,
    };
  }

  close() {
    this.setState({
      isVisible: false,
    });
  }

  render() {
    const { children, socialLinks, isHomePage } = this.props;
    const { isVisible } = this.state;
 
    return (
    isVisible ? (
        <Wrap>
       <div>
         <p>
        Biblioteka is setting up a Humanitarian Aid Donation Center for Ukraine in its library space in Peckham, London. We are focusing on life-saving items that are not easily available in Ukraine. The list can be found in the link below. Items collected will be delivered to the Polish border with Ukraine and from there will be distributed in the country.</p>
        <p>
        Library will be open for walk-ins <span>weekdays from 6:30pm - 10pm</span> (starting from 3rd of March) and weekends <span>10am - 10pm</span>. If you order online, you can put the Biblioteka address for the delivery.
        </p>
        <p>
        Thank you all so much for your support, it means the world to us. Love and stay safe.
        </p>
        <a href="https://linktr.ee/biblioteka_kyiv">Help Ukraine!</a>
        </div>
        <div className="closeButton">
          <a onClick={this.close.bind(this)}>Proceed to the website</a> 
        </div>
        </Wrap>
      ) : ''
    );
  }
}

export default ReliefFund;
