import React, { Component } from 'react';
import styled from '@emotion/styled';
import MEDIA_QUERY from '../constants/media-queries';

import Logo from './Logo';

const HeaderWrap = styled('div')`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 500;

  background-color: linear-gradient(#FFF 35%, #FFFFFF80 75%, #FFF0);

  padding: 1.5rem 1.85rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  ${MEDIA_QUERY.M} {
    flex-direction: row;
  }
`;

const SocialLink = styled.a`
  font-family: "Courier", monospace;
  font-size: 1.25em;
  line-height: 2em;
  text-decoration: none;
  color: #000000;

  &:hover {
    color: #3D58ED;
  }

  & + & {
    margin-left: 0.35em;
  }
`

const SocialLinksWrap = styled.div`
  margin-top: 0.5em;
  position: absolute;
  right: 2.5em;
  top: 0.75em;

  ${MEDIA_QUERY.M} {
    position: initial;
    margin-top: 0;
  }
`

class Header extends Component {
  render() {
    const { children, socialLinks, isHomePage } = this.props;
    console.log(socialLinks)
    return (
      <HeaderWrap>
        <Logo url='/' isHomePage={isHomePage}/>
        <SocialLinksWrap>
          {/* <span>/</span> */}
          <SocialLink href={socialLinks.instagram}>ig</SocialLink>
          <SocialLink href={socialLinks.telegram}>tg</SocialLink>
        </SocialLinksWrap>
        {children}
      </HeaderWrap>
    );
  }
}

export default Header;
