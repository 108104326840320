const slugify = require('slugify');

module.exports = function (input) {
  const JOIN_SYMBOL = '-';

  let slug = slugify(input, {
    replacement: JOIN_SYMBOL,
    remove: /[$*_+~.()'"!\-:@#]/g,
    lower: true,
  });

  slug = slug.split(JOIN_SYMBOL).slice(0, 4).join(JOIN_SYMBOL);

  return slug;
}