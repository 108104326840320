import React, { Component } from 'react';

import BookPreview from './BookPreview';


export default class ImageSurface extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hoveredBookId: null,
    };

    this.handleMouseMove = this.handleMouseMove.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousemove', this.handleMouseMove);
  }

  componentWillUnmount() {
    document.removeEventListener('mousemove', this.handleMouseMove);
  }
  
  handleMouseMove(e) {
    e.stopPropagation();
    let target = e.target;
    // Ignore <html />
    if (target.nodeName === 'HTML') {
      if ( this.state.hoveredBookId === null ) {
        return;
      } else {
        this.setState({ hoveredBookId: null });
      }
      return;
    };

    while (target.parentElement && !target.dataset.bookId) {
      target = target.parentElement;
    }
    const id = target.dataset.bookId;
    if (id) {
      if (this.state.hoveredBookId !== id) {
        this.setState({ hoveredBookId: id });
      }
    } else {
      this.setState({ hoveredBookId: null });
    }
  }

  render() {
    const { imageEdges } = this.props;
    const { hoveredBookId } = this.state;
    
    const images = imageEdges.map((edge, index) => {
      const imageData = edge.node;
      return <BookPreview 
              imageData={imageData} 
              key={imageData.id}
              bookId={imageData.id}
              index={index}
              isHovered={hoveredBookId === imageData.id}
            />
    });

    return (
      <div>
        {images}
      </div>
    );
  }
}

