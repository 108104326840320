import React, { Component } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Link } from 'gatsby';

import BookPreviewInfoOverlay from './BookPreviewInfoOverlay';
import BookPreviewImage from './BookPreviewImage';

const createSlug = require('../utils/createSlug')

export default class BookPreview extends Component {
  constructor(props) {
    super(props);

    const { floor, random } = Math;

    let windowWidth = 320, windowHeight = 480;

    if (typeof window !== `undefined`) {
      windowWidth = window.innerWidth;
      windowHeight = window.innerWidth;
    }
    const WIDTH_SPREAD = windowWidth < 768 ? 100 : 120;
    const smallScreenMultiplier = windowWidth < 1024 ? 3.0 * (1024 / windowWidth) : 1.0;

    this.state = {
      xPos: floor(random() * (windowWidth - 240)),
      yPos: floor(windowHeight * 0.05 + random() * windowHeight * 2.0 * smallScreenMultiplier),
      maxWidth: WIDTH_SPREAD + floor(random() * WIDTH_SPREAD),
      isLoaded: false,
    };

    this.handleImageLoad = this.handleImageLoad.bind(this);
  }

  handleImageLoad() {
    this.setState({ isLoaded: true });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.isLoaded !== this.state.isLoaded) return true;

    if (this.state.isLoaded && nextProps.isHovered !== this.props.isHovered) {
      return true;
    }

    return false;
  }

  render() {
    const { imageData, index, isHovered, bookId } = this.props;
    const { xPos, yPos, maxWidth, isLoaded } = this.state;
    const { imagelink, name } = imageData;

    const bookLink = `/books/${createSlug(imageData.name)}`;

    const wrapStl = {
      zIndex: `${isLoaded && isHovered ? 400 : index}`,
      top: `${yPos}px`,
      left: `${xPos}px`,
    }

    const imgStl = {
      maxWidth: `${maxWidth}px`,
    }

    const Wrap = styled.div`
      position: absolute;
      cursor: default;
      display: block;
    `

    const wrapCls = css`
      &:hover img {
        filter: brightness(30%);
        box-shadow: 0 1px 50px #3D58ED;
      }
    `;

    return (
      <Wrap 
        style={wrapStl} 
        className={wrapCls}
        data-book-id={bookId}
      >
        <Link to={bookLink}>
          <BookPreviewInfoOverlay 
            imageData={imageData}
            isHovered={isHovered && isLoaded}
          />
          <BookPreviewImage 
            src={imagelink} 
            alt={name} 
            style={imgStl} 
            isHovered={isLoaded && isHovered}
            loadCb={this.handleImageLoad}
            isLoaded={isLoaded}
          />
        </Link>
      </Wrap>
    )
  }
}
