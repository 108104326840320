import React from 'react';
import { graphql } from 'gatsby';

import ImageSurface from '../components/ImageSurface';
import Layout from '../components/Layout';
import ReliefFund from '../components/ReliefFund';


const IndexPage = ({ data, location }) => {
  const imageEdges = data.allGoogleSpreadsheetContentWebContent.edges;

  return (
    <Layout location={location}>
      <ReliefFund />
      <ImageSurface imageEdges={imageEdges}/>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query SpreadsheetQuery {
    allGoogleSpreadsheetContentWebContent {
      edges {
        node {
          publishers: publisher_s_
          city
          name
          authorseditors: author_s__editor_s_
          year
          id
          imagelink: imageLink
        }
      }
    }
  }
`;
