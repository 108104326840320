import React, { Component, Fragment } from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';

import MEDIA_QUERIES from '../constants/media-queries';

const LogoLink = styled(Link)`
  text-decoration: none;
  display: flex;
`;

class Logo extends Component {
  render() {
    const { url } = this.props;

    let onHomePage = true;
    
    const logoContent = 'Бібліотека'

    const LogoWrap = styled('header')`
      width: 0;
      min-width: 7em;
      height: 1.2em;
      margin-top: 0.15em;
      padding: 0.3em;

      line-height: 0.75em;
      box-shadow: 0 1px 50px #3D58EDD6;
      border-radius: 5rem;
      color: #000000;

      text-align: center;
      font-size: 1.25em;

      font-family: "Courier", monospace;
      font-weight: 600;
      text-transform: uppercase;

      user-select: none;
      &:hover {
        color: #FFFFFF;
        background: #3D58ED;
      }

      transition: min-width 0.35s ease;

      ${MEDIA_QUERIES.S} {
        margin-top: 0;
        font-size: 1.75em;
      }
    `;

    return (
      <LogoLink to={url}>
        <LogoWrap>
          {logoContent}
        </LogoWrap>
      </LogoLink>
    );
  }
}

export default Logo;
