import React, { Component } from 'react';
import styled from '@emotion/styled';

const Overlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  font-size: 0.75em;
  font-family: "Courier", monospace;
  color: #FFFFFF;
  padding: 0.5em;
  z-index: 700;

  cursor: pointer;

  h5 {
    margin-bottom: 0.25em;
  }
`

const OverlayInfoContainer = styled.div`
  display: flex;
  flex-direction: column;

  > div:last-child {
    margin-top: 0.0em;
  }
`
//`;

class BookPreviewInfoOverlay extends Component {
  render() {
    const { isHovered, imageData } = this.props;

    if (!isHovered) {
      return null;
    }

    return (
      <Overlay>
          <h5>{imageData.name}</h5>
          <OverlayInfoContainer>
            <div>{imageData.authors}</div>
            <div>{imageData.publishers}</div>
            <div>{imageData.year}</div>
          </OverlayInfoContainer>
      </Overlay>
    );
  }
}

export default BookPreviewInfoOverlay;
