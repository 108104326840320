import React from 'react';
import Helmet from 'react-helmet';
import styled from '@emotion/styled';
import { StaticQuery, graphql } from 'gatsby';

import '../styles/index.css';
import favicon from '../../assets/favicon.ico';

import MEDIA_QUERY from '../constants/media-queries';
import Header from '../components/Header';
import MenuLink from '../components/MenuLink';


const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  margin-top: 8em;
  padding: 1.5rem 1.45rem;

  ${MEDIA_QUERY.M} {
    padding: 1.5rem 1.85rem;
    margin-top: 6em;
  }
`;

const MenuLinksWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;

  ${MEDIA_QUERY.M} {
    justify-content: space-between;
    width: 30%;
  }
`;

const Layout = ({ children, data, location }) => {

  const { siteMetadata, pathPrefix } = data.site;
  const { links } = siteMetadata;

  let isHomePage = false;
  if (location.pathname.replace(pathPrefix, '') === '/') {
    isHomePage = true;
  };

  return (
    <div>
      <Helmet
        title={siteMetadata.title}
        meta={[
          { name: 'description', content: 'Bibliotheca for printed matter. Kyiv.' },
          { name: 'keywords', content: 'library, books, zines, printed matter, ukraine, kyiv' },
        ]}
      >
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" type="image/ico" href={favicon} sizes="16x16" />
      </Helmet>
      <Header socialLinks={links} isHomePage={isHomePage}>
        <MenuLinksWrapper>
          <MenuLink to='/about/' >
            About
          </MenuLink>
          <MenuLink to='/events/' >
            Events
          </MenuLink>
          <MenuLink to='/void/' >
            Void
          </MenuLink>
        </MenuLinksWrapper>
      </Header>
      <Content>
        {children}
      </Content>
    </div>
  )
};

export default ({ children, location }) => (
  <StaticQuery
    query={graphql`
      query SiteMetadataQuery {
        site {
          pathPrefix
          siteMetadata {
            title
            announcement {
              url
              text
            }
            links {
              instagram
              telegram
            }
          }
        }
      }
    `}
    render={data => Layout({ children, data, location })}
  />
)
