import React, { Component } from 'react';
import styled from '@emotion/styled';
import Colors from '../constants/colors';

const ImageWrap = styled.div`
  position: relative;
`

const LoadingPlaceholder = styled.div`
  position: absolute;
  background: ${Colors.PURPLE};
  top: 0;
  left: 0;
  pointer-events: none;

`

class BookPreviewImage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      w: 0,
      h: 0,
    };

    this.img = null;
    this.interval = null;
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      let w = this.img.width;
      let h = this.img.height;
      if (w !== 0 && h !== 0) {
        clearInterval(this.interval);
        this.setState({ w, h });
      };
    }, 35);
  }
  
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { isHovered, loadCb, isLoaded, ...other } = this.props;

    let placeholder = null;
    if (!isLoaded) {
      placeholder = <LoadingPlaceholder 
        style={{
          width: `${this.state.w}px`,
          height: `${this.state.h}px`,
        }}
      />
    }

    return (
      <ImageWrap>
        <img 
          onLoad={this.props.loadCb}
          ref={(el) => {this.img = el;}}
          {...other}
        />
        {placeholder}
      </ImageWrap>
    );
  }
}

export default BookPreviewImage;
